<template>
    <div class="container">

        <div class="row">

            <div class="col-lg-12">
                <v-breadcrumbs :items="breadcumbs">
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item
                            :to="item.href"
                            class="text-subtitle-2 crumb-item"
                            :disabled="item.disabled"
                            exact
                        >
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>
            </div>

            <div class="col-lg-12">

                <div class="card elevation-5">
                    <div class="card-body">
                        <v-spacer></v-spacer>
                        <div class="row">
                            <div class="col-lg-4 col-6">
                                <v-autocomplete v-model="findCustomerModel" :items="findCustomerLists" item-value="cust_id" item-text="cust_name" :search-input.sync="searchCustomer"
                                cache-items flat hide-no-data hide-details dense clearable label="Customer" prepend-icon="mdi-account-multiple" ></v-autocomplete>
                            </div>
                            <div class="col-lg-2 col-6">
                                <v-autocomplete v-model="brand" :items="brands" item-value="brand_id" item-text="descr" hide-details dense clearable label="Brand" prepend-icon="mdi-watermark" ></v-autocomplete>
                            </div>
                            <div class="col-lg-2 col-6">
                                <v-dialog ref="dialog" v-model="modalStartPeriod" :return-value.sync="startPeriodModel" persistent width="290px" >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="startPeriodModel" label="Start Period" readonly v-bind="attrs" v-on="on" dense clearable prepend-icon="mdi-calendar-check"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="startPeriodModel" type="month" scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="modalStartPeriod = false">Cancel</v-btn>
                                        <v-btn text color="primary" @click="$refs.dialog.save(startPeriodModel)">OK</v-btn>
                                    </v-date-picker>
                                </v-dialog>
                            </div>
                            <div class="col-lg-2 col-6">
                                <v-dialog ref="dialog2" v-model="modalEndPeriod" :return-value.sync="endPeriodModel" persistent width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="endPeriodModel" label="End Period" readonly v-bind="attrs" v-on="on" dense clearable prepend-icon="mdi-calendar-check"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="endPeriodModel" type="month" scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="modalEndPeriod = false">Cancel</v-btn>
                                        <v-btn text color="primary" @click="$refs.dialog2.save(endPeriodModel)">OK</v-btn>
                                    </v-date-picker>
                                </v-dialog>
                            </div>
                            <div class="col-lg-2 col-12">
                                <v-btn class="mt-3" color="primary" elevation="4" rounded small @click="getChartData()">Search<v-icon right dark>mdi-magnify</v-icon></v-btn>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div class="col-lg-12 col-12 cardResult">

                <div class="card elevation-5">
                    <div class="card-body">
                            
                        <div id="chartContainer1" style="height: 350px; width: 100%;"></div>
                            
                    </div>
                </div>

            </div>

            <div class="col-lg-6 col-12 cardResult">
                <div class="card elevation-5">
                    <div class="card-body">
                        <h5 class="card-title">Top 5 Customer Complaint</h5>
                        <v-data-table :headers="top5ComplaintHeaders" :items="top5ComplaintItemLists" class="elevation-1" page-count="5" dense>
                            <template v-slot:[`item.no`]="{ index }">
                                {{ index + 1 }}
                            </template>
                            <template v-slot:[`item.Jml_LoC`]="{ item }">
                                <span>{{ formatNumber2(item.Jml_LoC) }}</span>
                            </template>
                            <template v-slot:[`item.total_wgt`]="{ item }">
                                <span>{{ formatNumber2(item.total_wgt) }}</span>
                            </template>
                            <template v-slot:[`item.persentase`]="{ item }">
                                <span v-if="item.total_wgt_rtc != '0'">
                                    {{ formatNumber(100 - ((item.total_wgt_rtc/item.total_wgt) * 100 )) }} %
                                </span>
                                <span v-else>
                                    0 %
                                </span>
                            </template>
                        </v-data-table>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-12 cardResult">
                <div class="card elevation-5">
                    <div class="card-body">
                        <h5 class="card-title">Top 5 Reply to Complaint (RtC)</h5>
                        <v-data-table :headers="top5IssueHeaders" :items="top5IssueItemLists" class="elevation-1" page-count="5" dense>
                            <template v-slot:[`item.no`]="{ index }">
                                {{ index + 1 }}
                            </template>
                            <template v-slot:[`item.Jml_LoC`]="{ item }">
                                <span>{{ formatNumber2(item.Jml_LoC) }}</span>
                            </template>
                            <template v-slot:[`item.persentase`]="{ item }">
                                <span v-if="item.total_wgt_rtc != '0'">
                                    {{ formatNumber( (item.total_wgt_rtc/item.total_wgt) * 100 ) }} %
                                </span>
                                <span v-else>
                                    0 %
                                </span>
                            </template>
                        </v-data-table>
                    </div>
                </div>
            </div>

            <v-dialog v-model="dialogDetails" max-width="80%">

                <div class="card elevation-5">
                    <div class="card-body">
                        <h5 class="card-title">Period : {{ periodDetailModel }} </h5>
                        <v-data-table :headers="headers" :items="itemLists" class="elevation-1" page-count="5" :search="searchItem" :loading="loadingDatatable" dense>
                            <template v-slot:[`item.no`]="{ index }">
                                {{ index + 1 }}
                            </template>
                            <template slot="body.append">
                                <tr class="pink--text">
                                    <th class="title">Totals</th>
                                    <th class="title"></th>
                                    <th class="title text-right">{{ sumField('Jml_LoC') }}</th>
                                    <th class="title text-right">{{ sumField('total_wgt') }}</th>
                                    <th class="title text-right">{{ sumField('total_baby_coil') }}</th>
                                    <th class="title text-right">{{ sumField('total_rtc') }}</th>
                                    <th class="title text-right">{{ sumField('wgt_rtc') }}</th>
                                </tr>
                            </template>
                            <template v-slot:[`item.total_wgt`]="{ item }">
                                {{ item.total_wgt }} Kg
                            </template>
                            <template v-slot:[`item.rtc_wgt`]="{ item }">
                                {{ item.rtc_wgt }} Kg
                            </template>
                            <template v-slot:[`item.persen_rtc`]="{ item }">
                                <span v-if="item.rtc_wgt != '0'">
                                    {{ formatNumber(100 - ((item.rtc_wgt/item.total_wgt) * 100 )) }} %
                                </span>
                                <span v-else>
                                    0 %
                                </span>
                            </template>
                        </v-data-table>
                            
                    </div>
                </div>

            </v-dialog>

        </div>

    </div>
    
</template>

<script>

export default {
    data() {
        return {
            breadcumbs: [
                {
                text: 'Sunrise Web',
                disabled: false,
                href: '/admin/sr',
                },
                {
                text: 'Sales Marketing Analysis',
                disabled: false,
                href: '#',
                },
                {
                text: 'List of Complain',
                disabled: true,
                href: '#',
                },
            ],
            itemLists: [],
            headers: [
                { text: 'No.', value: 'no', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'Customer', value: 'cust_name', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'Jml. LoC', value: 'Jml_LoC', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'Total Tonase LoC', value: 'total_wgt', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'Jml. Baby Coil LoC', value: 'total_baby_coil', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'Jml. RtC', value: 'total_rtc', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'Tonase RtC', value: 'rtc_wgt', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'Penyelesaian(%)', value: 'persen_rtc', align: 'center', class: 'primary--text blue lighten-5' },
                { text: 'Justified', value: 'wgt_just', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'Unjustified', value: 'wgt_unjust', align: 'right', class: 'primary--text blue lighten-5' },
            ],
            searchItem: '',
            loadingDatatable: false,
            dialogDetails: false,
            findCustomerModel: '',
            findCustomerLists: [],
            searchCustomer: null,
            modalStartPeriod: false,
            startPeriodModel: '',
            modalEndPeriod: false,
            endPeriodModel: '',
            startPeriodFormatted: '', 
            endPeriodFormatted: '',
            periodDetailModel: '',
            top5ComplaintItemLists: [],
            top5ComplaintHeaders: [
                { text: 'No.', value: 'no', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'Customer', value: 'cust_name', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'Jml. LoC', value: 'Jml_LoC', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'Tonase (Kg)', value: 'total_wgt', align: 'right', class: 'primary--text blue lighten-5' },
                { text: '(%)', value: 'persentase', align: 'center', class: 'primary--text blue lighten-5' },
            ],
            top5IssueItemLists: [],
            top5IssueHeaders: [
                { text: 'No.', value: 'no', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'Customer', value: 'cust_name', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'Complaint', value: 'Jml_LoC', align: 'right', class: 'primary--text blue lighten-5' },
                { text: '(%)', value: 'persentase', align: 'center', class: 'primary--text blue lighten-5' },
            ],
            brand: '',
            brands: []
        }
    },
    async mounted(){
        
        await this.initialize()
        await this.getBrand()
    },
    methods:{
        
        async initialize(){
            
            this.startPeriodModel = this.currentPeriod()
            this.endPeriodModel = this.currentPeriod()

            this.getChartData()
            this.getTop5()

        },

        async getBrand(){
            await axios.get(`${process.env.VUE_APP_URL}/api/master/sr/brand`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.brands = res.data
            });
        },

        async getChartData(){

            this.$store.dispatch('setOverlay', true)
            $('.cardResult').hide()

            if (this.startPeriodModel) {
                this.startPeriodFormatted = new Date(this.startPeriodModel).toISOString().substr(0, 7).replace(/-/g,"")
            } else  {
                this.startPeriodFormatted = ''
            }

            if (this.endPeriodModel) {
                this.endPeriodFormatted = new Date(this.endPeriodModel).toISOString().substr(0, 7).replace(/-/g,"")
            } else  {
                this.endPeriodFormatted = ''
            }

            this.getTop5()
            
            await axios.post(`${process.env.VUE_APP_URL}/api/sr/ListOfComplain/getChartData`, { 

                cust_id: this.findCustomerModel ? this.findCustomerModel : '',
                brand_id: this.brand ? this.brand : '',
                startPeriod: this.startPeriodFormatted ? this.startPeriodFormatted : '',
                endPeriod: this.endPeriodFormatted ? this.endPeriodFormatted : ''

            },
            {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
            })
            .then(res => {

                $('.cardResult').show()
                this.renderChart(res.data.arr_list, res.data.arr_list2)
                this.$store.dispatch('setOverlay', false)

            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                console.log(err)

            })

        },

        async getTop5(){

            $('.cardResult').hide()

            await axios.post(`${process.env.VUE_APP_URL}/api/sr/ListOfComplain/getTop5`, { 

                startPeriod: this.startPeriodFormatted ? this.startPeriodFormatted : '',
                endPeriod: this.endPeriodFormatted ? this.endPeriodFormatted : '',
                brand_id: this.brand ? this.brand : '',

            },
            {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
            })
            .then(res => {

                console.log(res.data)

                this.top5ComplaintItemLists = res.data.arr_list
                this.top5IssueItemLists = res.data.arr_list

                $('.cardResult').show()

            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                console.log(err)

            })

        },

        renderChart(data, data2){

            var chart = new CanvasJS.Chart("chartContainer1", {
                animationEnabled: true,
                theme: "light2",
                title:{
                    text: "List of Complain Analysis Graph"
                },
                subtitles: [{
                    text: "Click for details",		
                    fontColor: "grey",
                }],
                axisX:{
                    crosshair: {
                        enabled: true,
                        snapToDataPoint: true
                    }
                },
                axisY: {
                    title: "List of Complain",
                    titleFontSize: 24,
                    crosshair: {
                        enabled: true,
                        snapToDataPoint: true
                    },
                    gridColor: "#005f73"
                },
                axisY2: {
                    title: "Weight(Kg)",
                    titleFontSize: 24,
                },
                legend: {
                    fontColor: "red",
                    cursor: "pointer",
                    itemclick: this.toggleDataSeries
                },
                toolTip:{
                    enabled: false,   //enable here
                    shared:true
                },
                data: [{
                    type: "column",
                    indexLabel: "{y}",
                    click: this.getDetails,
                    name: 'Total LoC',
                    indexLabelFontSize: 12,
                    showInLegend: true,
                    indexLabelPlacement: "top",  
                    indexLabelOrientation: "horizontal",
                    yValueFormatString: "###,###",
                    indexLabelFontColor: "red",
                    color: "#0f4c5c",
                    dataPoints: data,
                },
                {
                    type: "spline",
                    indexLabel: "{y}",
                    axisYType: "secondary",
                    name: 'Total Wgt',
                    indexLabelFontSize: 12,
                    showInLegend: true, 
                    indexLabelOrientation: "horizontal",
                    yValueFormatString: "###,### Kg",
                    lineColor: "#fb5607",
                    indexLabelFontColor: "red",
                    dataPoints: data2,
                }]
            });

            chart.render()

        },

        toggleDataSeries(e) {
            if (typeof (e.dataSeries.visible) === "undefined" || e.dataSeries.visible) {
                e.dataSeries.visible = false;
            } else {
                e.dataSeries.visible = true;
            }
            e.chart.render();
        },

        async getDetails(e){

            this.itemLists = []
            this.dialogDetails = true
            this.loadingDatatable = true
            this.periodDetailModel = ''

            await axios.post(`${process.env.VUE_APP_URL}/api/sr/ListOfComplain/getDetails`, {
                cust: this.findCustomerModel ? this.findCustomerModel : '',
                brand_id: this.brand ? this.brand : '',
                period: e.dataPoint.label,
            },
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {

                this.periodDetailModel = res.data.period
                this.itemLists = res.data.result
                this.loadingDatatable = false

            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                console.log(err)

            })

        },

        async getCustomer(id){

            await axios.post(`${process.env.VUE_APP_URL}/api/sr/ListOfComplain/getCustomer`, { 
                    id : id,
            },{
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
            })
            .then(res => {

                this.findCustomerLists = res.data.result

            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401'){
                    this.$router.push('/');
                }

            })

        },

        currentPeriod() {

            const current = new Date();
            current.setDate(current.getDate() + 20);

            const date = current.getFullYear() + '-' + ('0' + (current.getMonth())).slice(-2);      
            return date;
        },

        sumField(key) {
            return this.itemLists.reduce((a, b) => a + (b[key] || 0), 0)
        },

        formatNumber(value) {
            let val = (value/1).toFixed(2).replace('.', ',')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        },

        formatNumber2(value) {
            let val = (value/1).toFixed(0).replace('.', ',')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        },

    },
    watch: {
        searchCustomer(val) {
            val && val !== this.findCustomerModel && this.getCustomer(val)
        }
    }
    
}
</script>

<style scoped>

    .v-text-field {
        padding-top: 10px !important;
    }

    .v-breadcrumbs {
        padding: 5px 5px !important;
    }

    .badgeInfo {
        font-size: 1em;
        padding-bottom: 10px; 
        padding-left: 10px;
    }

    .cardCustom{
        padding-top: 10px;
    }

    .container{
        margin-bottom: 100px !important;
    }

    .v-text-field >>> input {
        font-size: 1em !important;
        font-weight: 100 !important;
        text-transform: capitalize !important;
    }
    .v-text-field >>> label {
        font-size: 0.8em !important;
    }
    .v-text-field >>> button {
        font-size: 0.8em !important;
    }

    .modal {
        margin: 0 auto; 
    }

</style>